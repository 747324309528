<template>
  <en-dialog :model-value="modelValue" title="合并调漆" @close="$emit('update:model-value', false)">
    <en-table
      :data="table.data"
      :method="table.get"
      :height="500"
      pagination
      :paging="table.paging"
      row-key="id"
      @selection-change="table.selection.change"
    >
      <en-table-column type="selection" reserve-selection :selectable="table.selection.selectable"> </en-table-column>
      <en-table-column label="收费标准" prop="chargingStandard.message"></en-table-column>
      <en-table-column label="工单流水号" prop="serialNo"></en-table-column>
      <en-table-column label="车牌" prop="plateNo"></en-table-column>
      <en-table-column label="手机号" prop="cellphone"></en-table-column>
      <en-table-column label="维修项目" prop="surfaceMessage"></en-table-column>
      <en-table-column label="工单日期" prop="preparedDatetime">
        <template #default="{ row }: { row: EnospraySprayDefinitions['WorkOrderQueryDto'] }">{{ formatDate(row.preparedDatetime) }}</template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { EnMessage, EnMessageBox } from '@enocloud/components'

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnospraySprayDefinitions['WorkOrderDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          this.table.get()
        }
      }
    }
  },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/workorder/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: 'NS' }
            }
          }
        },
        children: {
          selection: {
            data: [] as EnospraySprayDefinitions['WorkOrderQueryDto'][],
            change(rows: EnospraySprayDefinitions['WorkOrderQueryDto'][]) {
              this.table.selection.data = rows
            },
            selectable(row: EnospraySprayDefinitions['WorkOrderQueryDto']) {
              return (
                row.square &&
                row.id !== this.data?.id &&
                (this.table.selection.data.length < 9 || this.table.selection.data.map((item) => item.id).includes(row.id))
              )
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const selection = this.table.selection.data
            if (!selection.length) return EnMessage.warning('请先选择工单')
            const action = await EnMessageBox.confirm('系统将按钣面数分配色母用量到所选工单,是否继续?', '确认合并调漆', { type: 'warning' })
            if (action === 'confirm') {
            }
          }
        }
      }
    }
  }
})
</script>
